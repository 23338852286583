<template>
  <v-tabs
    v-model="tab"
    background-color="transparent"
    class="category-tabs"
    color="#39AF49"
    show-arrows
    @change="tabChanged"
  >
    <v-tab
      v-for="category in categories"
      :key="category.id"
      :data-id="'tab-' + category.id"
    >
      <font-awesome-icon class="mr-2" :icon="category.icon" />
      <span>{{ $t(category.label) }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tab: 0,
    }
  },

  created() {
    this.handleRouteChange()
  },

  watch: {
    '$route.query.category': 'handleRouteChange',
  },

  computed: {
    ...mapGetters('catalog', ['catalogCategory']),
  },

  methods: {
    ...mapActions('catalog', ['setCatalogCategory', 'resetFilter']),

    async handleRouteChange() {
      try {
        const selectedCategory = this.$route.query?.category
        if (selectedCategory !== undefined) {
          await this.changeCategory(selectedCategory)
          logEvent(events.catalogModule[`clicked_${selectedCategory}`])
        }
      } catch (error) {
        console.error('Error handling route change: ', error)
      }
    },

    async changeCategory(newCategory) {
      if (newCategory === undefined) {
        return
      }

      this.setCatalogCategory(newCategory)
      const tabIndex = this.categories.findIndex(
        (category) => category.name === newCategory
      )
      this.tab = tabIndex !== -1 ? tabIndex : 0
    },

    async tabChanged(newTab) {
      this.resetFilter()
      const category = this.categories[newTab]
      await this.setUrlCategory(category.name)
    },

    async setUrlCategory(newCategory) {
      await this.$router.replace({
        query: { ...this.$route.query, category: newCategory },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab--active {
  color: #39af49 !important;
  font-weight: 700;
  font-size: 14px;
}
.category-tabs {
  border: 1px solid #e6e9e6;
  background: #fff;
  box-shadow: 2px 6px 9px 0px rgba(0, 0, 0, 0.05);
  font-size: 14px;
}
::v-deep .v-slide-group__prev {
  @include d(m) {
    width: 28px;
    min-width: 28px;
  }
}
::v-deep .v-slide-group__next {
  @include d(m) {
    width: 28px;
    min-width: 28px;
  }
}
</style>
