<script>
import { mapActions, mapGetters } from 'vuex'
import DfDatePicker from '@/lib/Form/DatePicker'
import Loader from '@/components/Loaders/Loader'

export default {
  components: {
    DfDatePicker,
    Loader,
  },

  props: {
    filters: { type: Array, required: true },
    disabled: { type: Boolean, default: true },
  },

  data() {
    return {
      dialog: false,
      assetOwnerInputSearchValue: '',
      disableApplyBtn: false,
    }
  },

  methods: {
    ...mapActions('catalog', [
      'setSelectedOptions',
      'resetFilter',
      'fetchFilters',
    ]),

    async toggleCheckbox(section, option) {
      this.disableApplyBtn = true
      const selectedSectionOptions = this.selectedOptions[section] || []
      const index = selectedSectionOptions.indexOf(option.name)

      if (index !== -1) {
        selectedSectionOptions.splice(index, 1)
      } else {
        selectedSectionOptions.push(option.name)
      }
      const value =
        selectedSectionOptions && selectedSectionOptions?.length >= 1
          ? selectedSectionOptions
          : null
      this.setSelectedOptions({
        section,
        selectedSectionOption: value,
      })
      await this.fetchFilters()
      this.disableApplyBtn = false
    },

    async apply() {
      this.$emit('filterApply', this.selectedOptions)
      this.dialog = false
    },

    close() {
      this.dialog = false
    },

    filteredOptions(filter) {
      if (filter.section === 'asset_owner') {
        const filterText = this.assetOwnerInputSearchValue.toLowerCase() || ''
        return filter.list.filter((option) =>
          option.name.toLowerCase().includes(filterText)
        )
      }
      return filter.list
    },

    getOptionTranslate(option) {
      const translationKey = `Catalog.Options.${option.message_key}`

      return this.$te(translationKey)
        ? this.$t(translationKey)
        : option.message_key
    },

    async handleChangeFilter(event, section) {
      this.disableApplyBtn = true
      this.setSelectedOptions({ section, selectedSectionOption: event })
      await this.fetchFilters()
      this.disableApplyBtn = false
    },

    async reset() {
      await this.resetFilter()
      await this.fetchFilters()
    },

    isOptionChecked(section, value) {
      const selectedSectionOptions = this.selectedOptions[section] || []
      return selectedSectionOptions.includes(value)
    },

    addSelectedStateToItem(section, value) {
      return section?.name === value ? true : false
    },
  },

  computed: {
    ...mapGetters('crops', ['isSoybean']),
    ...mapGetters('catalog', ['selectedOptions', 'loadingFilterOptions']),
  },
}
</script>

<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" class="open" :disabled="disabled">
        <p>{{ $t('filter') }}</p>
        <v-icon size="18" color="#39AF49">mdi-filter</v-icon>
      </button>
    </template>
    <v-card>
      <v-card-title>
        <p>{{ $t('filter') }}</p>
        <button @click="close">
          <v-icon size="18" color="#AAB2A9">mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels accordion flat multiple>
          <v-expansion-panel v-for="(filter, index) in filters" :key="index">
            <v-expansion-panel-header>
              {{ $t(`Catalog.${filter.section}`) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="overflow-y-auto"
              style="max-height: CALC(100vh - 343px)"
              v-if="filter.type === 'radio'"
            >
              <div v-if="filter.section === 'asset_owner'" style="width: 100%">
                <input
                  v-model="assetOwnerInputSearchValue"
                  :placeholder="$t('Catalog.owner_search')"
                  class="name"
                />
                <v-icon size="18" color="#5B6459" class="inside">
                  mdi-magnify
                </v-icon>
              </div>
              <v-radio-group>
                <v-radio
                  :off-icon="
                    addSelectedStateToItem(
                      selectedOptions[filter.section],
                      option.name
                    )
                      ? '$radioOn'
                      : '$radioOff'
                  "
                  @click="handleChangeFilter(option, filter.section)"
                  class="df-radio"
                  color="#39af49"
                  v-for="(option, optionIndex) in filteredOptions(filter)"
                  :key="optionIndex"
                  :label="getOptionTranslate(option)"
                  :value="option.name"
                ></v-radio>
              </v-radio-group>
            </v-expansion-panel-content>

            <v-expansion-panel-content
              v-else-if="filter.type === 'checkbox'"
              class="overflow-y-auto"
              style="max-height: CALC(100vh - 343px)"
            >
              <div
                v-for="(option, multi_index) in filter.list"
                :key="multi_index"
                class="multiple-list"
              >
                <input
                  type="checkbox"
                  :value="option.name"
                  @change="toggleCheckbox(filter.section, option)"
                  :checked="isOptionChecked(filter.section, option.name)"
                />
                {{ getOptionTranslate(option) }}
              </div>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-else-if="filter.type === 'slider'">
              <v-range-slider
                @change="(e) => handleChangeFilter(e, filter.section)"
                step="0.1"
                track-color="#C3D1E9"
                track-fill-color="#1A2B46"
                thumb-color="#1A2B46"
                thumb-label="always"
                :min="0"
                :max="10"
              ></v-range-slider>
            </v-expansion-panel-content>

            <v-expansion-panel-content
              v-else-if="filter.type === 'date_period'"
            >
              <df-date-picker
                v-model="selectedOptions[filter.section]"
                :min="filter.preferred_sowing_start"
                :max="filter.preferred_sowing_end"
              ></df-date-picker>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <button class="close" @click="close">
          <u>{{ $t('cancelar') }}</u>
        </button>
        <button class="close" @click="reset">
          <u>{{ $t('clean_filter') }}</u>
        </button>
        <button class="apply" @click="apply">
          <p>{{ $t('filter') }}</p>
          <v-icon size="18" color="#FFFFFF">mdi-check-bold</v-icon>
        </button>
      </v-card-actions>
      <v-overlay :opacity="0.1" :absolute="true" :value="loadingFilterOptions">
        <loader :show-loading-text="false"></loader>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.open {
  background-color: #fff;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  padding: 4px 12px;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  color: #3f4e3c;
  width: 130px;
}

.apply {
  padding: 4px 12px;
  width: 85px;
  height: 32px;
  background-color: #39af49;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.close {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: #5b6459;
}
.open p,
.apply p {
  display: contents;
}
::v-deep .v-dialog {
  border-radius: 8px;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.05);
}

.v-card__title {
  font-family: 'Rubik';
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1a2b46;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.v-card__text {
  background-color: #f8f9f8;
  padding: 0px 10px !important;
}

.v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.v-expansion-panel-content {
  padding: 0px 24px;
}

.v-expansion-panels,
.v-expansion-panel,
.v-expansion-panels button {
  background-color: #f8f9f8 !important;
}

::v-deep .v-slider--horizontal .v-slider__track-container {
  height: 10px;
}
::v-deep .v-slider--horizontal .v-slider__track-fill {
  background-color: #1a2b46 !important;
}

::v-deep .v-slider__track-background {
  border-radius: 8px;
}

::v-deep .v-slider__thumb-label {
  background-color: #1a2b46;
  border-radius: 30px !important;
  position: relative;
  top: 5px;
  height: 25px !important;
  width: 25px !important;
}

::v-deep .v-slider__thumb-label span {
  position: relative;
  bottom: 20px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #1a2b46;
}

.v-expansion-panels button {
  box-shadow: none;
  border: none;
  width: 100%;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #1a2b46;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 10px 0px 0px 0px !important;
}

@media (max-width: 960px) {
  .open {
    width: 40px;
  }
  .open p {
    display: none;
  }
}

.df-radio {
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #e6e9e6;
  background-color: #e6e9e6;
}

.name {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 6px 14px;
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  height: 40px;
}

.v-icon.inside::before {
  position: absolute;
  top: -10px;
  left: -30px;
}

.multiple-list {
  background-color: #e6e9e6;
  border-radius: 8px;
  min-height: 40px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #0e180c;
  padding: 12px;
  margin: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.multiple-list input {
  appearance: none;
  background: #f8f9f8;
  border: 1px solid #aab2a9;
  border-radius: 4px;
  margin-right: 4px;
  min-width: 16px;
  min-height: 16px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.multiple-list input:checked {
  border: 5px solid #39af49;
}
</style>
