<template>
  <v-row align="center">
    <v-chip
      :class="textChip"
      :style="getTextColor"
      :color="color"
      v-bind="$attrs"
    >
      <slot></slot>
      <font-awesome-icon v-if="icon" :class="flank" :icon="icon" />
    </v-chip>
  </v-row>
</template>

<script>
export default {
  name: 'DfChips',
  computed: {
    getTextColor() {
      return {
        color: this.textColor || '#fff',
      }
    },
  },

  props: {
    color: {
      type: String,
      default: '#39af49',
    },

    textColor: {
      type: String,
      default: '#fff',
    },

    flank: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    textChip: {
      type: String,
      default: 'text-chip',
    },
  },
}
</script>

<style scoped>
.text-chip {
  font-family: Source Sans Pro;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  padding-right: 5px;
  padding-left: 5px;
}
</style>
