<template>
  <v-container v-show="!isNematicide" class="pa-0" fluid>
    <v-radio-group v-model="selectedCrop" row @change="cropChanged">
      <v-radio
        v-for="crop in enabledCrops"
        class="df-radio"
        color="#39af49"
        :disabled="loadingFilterData || isNematicide"
        :key="crop.id"
        :label="cropLabel(crop)"
        :value="crop.id"
      />
    </v-radio-group>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CropSelector',

  data() {
    return {
      selectedCrop: null,
      enabledCrops: [
        { id: 1, name: 'Soybean', messageKey: 'crop.soybean' },
        { id: 2, name: 'Wheat', messageKey: 'crop.wheat' },
      ],
    }
  },

  mounted() {
    const crop_id = this.currentCrop?.id || 1
    this.selectedCrop = crop_id
  },

  computed: {
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('catalog', ['loadingFilterData', 'catalogCategory']),
    isNematicide() {
      return this.catalogCategory === 'nematicide'
    },
  },

  methods: {
    ...mapActions('crops', ['setCurrentCrop']),
    cropChanged(cropIndex) {
      this.selectedCrop = cropIndex
      this.setCurrentCrop(cropIndex)
    },
    cropLabel(crop) {
      return this.$t(`Catalog.FilterBar.Crop.${crop.name.toLowerCase()}`)
    },
  },
}
</script>

<style scoped>
.df-radio {
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #e6e9e6;
  background-color: #e6e9e6;
}
.v-item--active {
  border: 2px solid #96dda0;
  background-color: #fff;
}
</style>
