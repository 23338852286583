<script>
import { mapActions, mapGetters } from 'vuex'
import FilterModal from './FilterModal'

export default {
  name: 'CatalogFilter',

  components: {
    FilterModal,
  },
  props: {
    cropId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      itemName: '',
      selectedSortItem: 'name:asc',
      debounceTimer: null,
    }
  },

  async created() {
    await this.refreshFiltersAndCatalog()
  },

  watch: {
    async catalogCategory() {
      if (this.catalogCategory) {
        await this.refreshFiltersAndCatalog()
      }
    },
    async cropId() {
      await this.refreshFiltersAndCatalog()
    },
    itemName: {
      handler: 'handleInput',
    },
  },

  methods: {
    ...mapActions('catalog', [
      'fetchFilters',
      'fetchCatalogList',
      'setSelectedOptions',
      'setSelectedSortItem',
      'resetCatalogItems',
    ]),
    async applySort(value) {
      this.setSelectedSortItem(value)
      await this.filterApply()
    },
    async filterApply() {
      await this.fetchCatalogList()
    },
    async refreshFiltersAndCatalog() {
      this.resetCatalogItems()
      await this.fetchFilters()
      this.selectFirstSortItem()
      await this.applySort(this.selectedSortItem)
    },
    selectFirstSortItem() {
      if (this.sortItems?.list?.length > 0) {
        this.selectedSortItem = this.sortItems.list[0].message_key
      }
    },
    async handleInput() {
      if (this.itemName.length >= 3 || this.itemName.length == 0) {
        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.setSelectedOptions({
            section: 'name',
            selectedSectionOption: this.itemName,
          })
          this.$nextTick(async () => {
            await this.fetchCatalogList()
          })
        }, 2000)
      }
    },
  },

  computed: {
    ...mapGetters('catalog', [
      'catalogCategory',
      'loadingFilterOptions',
      'filters',
      'sortItems',
    ]),
  },
}
</script>

<template>
  <div class="header justify-space-between">
    <div style="width: 100%">
      <input
        v-model="itemName"
        :placeholder="$t('Catalog.FilterBar.name')"
        class="name"
        :disabled="loadingFilterOptions"
      />
      <v-icon size="18" color="#5B6459" class="inside">mdi-magnify</v-icon>
    </div>
    <div>
      <v-select
        class="custom-input"
        v-model="selectedSortItem"
        :items="sortItems.list"
        item-value="message_key"
        :item-text="(item) => $t(`Catalog.Options.Sort.${item.name}`)"
        color="#39af49"
        style="background: white"
        outlined
        dense
        hide-details
        @change="applySort"
        :disabled="loadingFilterOptions"
        :loading="loadingFilterOptions"
      >
      </v-select>
    </div>
    <div>
      <filter-modal
        :filters="filters"
        @filterApply="filterApply"
        :disabled="loadingFilterOptions"
      ></filter-modal>
    </div>
  </div>
</template>

<style scoped lang="scss">
.name,
.sort {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 6px 14px;
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  height: 40px;
}
::v-deep .custom-input.v-text-field {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  fieldset {
    border: 1px solid rgb(230, 233, 230);
  }
}
.header {
  display: flex;
  gap: 6px;
  align-items: center;
  width: CALC(100% - 25px);
  margin-bottom: 12px;
}
.sort {
  width: 180px;
}
.col {
  padding: 10px 12px;
}
.v-icon.inside::before {
  position: absolute;
  top: -10px;
  left: -30px;
}
p,
span {
  font-family: 'Source Sans Pro';

  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #aab2a9;
}
.uppercase:first-letter {
  text-transform: uppercase;
}
@media (max-width: 960px) {
  .header {
    width: 100%;
  }
}
</style>
