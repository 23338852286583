<template>
  <div>
    <coming-soon></coming-soon>
  </div>
</template>

<script>
import ComingSoon from '@/components/ComingSoon/ComingSoon.vue'

export default {
  components: {
    ComingSoon,
  },
}
</script>
