<template>
  <div class="d-flex py-2">
    <font-awesome-icon
      v-if="hasIcon"
      class="align-self-center"
      color="#788476"
      :icon="icon"
    />
    <div class="d-flex flex-column align-start ml-2">
      <span v-if="hasTitle" class="info-title">{{ title }}</span>
      <span
        v-if="hasDescription"
        class="info-description pt-1 text-left"
        v-html="description"
      >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DfInfo',

  props: {
    icon: { default: '', type: String },
    title: { default: '', type: String },
    description: { default: '', type: String },
  },

  computed: {
    hasIcon() {
      return typeof this.icon !== 'undefined' && this.icon !== null
    },

    hasTitle() {
      return typeof this.title !== 'undefined' && this.title !== null
    },

    hasDescription() {
      return (
        typeof this.description !== 'undefined' && this.description !== null
      )
    },
  },
}
</script>

<style>
.info-title {
  color: #788476;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.info-description {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
</style>
