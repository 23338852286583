<template>
  <v-card class="df-list-item" elevation="0" @click="handleClick">
    <div class="d-flex input-name align-center">
      <span class="input-name-content">
        <slot></slot>
      </span>
    </div>

    <div class="d-flex right-content align-center">
      <slot name="right-content"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DfListItem',

  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.df-list-item {
  font-family: 'Rubik';
  padding: 12px 24px 12px 16px;
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  display: grid;
  grid-template-areas: 'input-name right-content';
  grid-template-columns: auto auto 30px;
  cursor: pointer;
}
.input-name {
  grid-area: input-name;
}
.input-name-content {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.right-content {
  grid-area: right-content;
  gap: 16px;
  justify-content: flex-end !important;
}
@media (max-width: 439px) {
  .v-card {
    grid-template-areas: 'input-name' 'right-content';
  }
  .right-content {
    justify-content: flex-start !important;
  }
}
</style>
