<template>
  <v-card class="mt-12 ml-md-12 mr-md-12 mx-6 py-6 px-6 rounded-lg">
    <v-container id="canvas">
      <v-row class="d-flex justify-space-around" align="center">
        <v-col cols="12" lg="6" md="4" sm="12">
          <df-chips x-small class="mt-n4 mx-auto">
            {{ !planDisabled ? $t('news') : $t('Catalog.ComingSoon.label') }}
          </df-chips>
          <h1 class="coming-soon__title">
            {{ $t('Catalog.ComingSoon.title') }}
          </h1>
          <span class="coming-soon__description">
            {{ $t('Catalog.ComingSoon.description') }}
          </span>
          <p v-if="!planDisabled">
            <df-button
              class="mt-4"
              :flank="'fa-pull-right pl-1 fa-1x'"
              :icon="'phone-alt'"
              @click="talkSeller"
              width="70%"
            >
              {{ $t('talk_seller') }}
            </df-button>
          </p>
          <p v-else-if="!hasCatalogFeedback" class="d-flex mt-4">
            <df-button
              id="like-button"
              variant="secondary"
              flank="fa-pull-right pl-1 fa-1x"
              icon="thumbs-up"
              color-icon="#39AF49"
              @click="likeFeature(true)"
            >
              {{ $t('Catalog.ComingSoon.Buttons.like') }}
            </df-button>
            <df-button
              id="dislike-button"
              variant="secondary"
              class="ml-4"
              flank="fa-pull-right pl-1 fa-1x"
              icon="thumbs-down"
              color-icon="#39AF49"
              @click="likeFeature(false)"
            >
              {{ $t('Catalog.ComingSoon.Buttons.dislike') }}
            </df-button>
          </p>

          <p class="mt-4 coming-soon__feedback-congratulations" v-else>
            {{ $t('Catalog.ComingSoon.Messages.feedbackCongratulations') }}
          </p>
        </v-col>
        <v-col lg="6" md="8" sm="12" class="d-flex justify-center">
          <v-img :src="imgBanner" :max-width="planDisabled ? '250px' : ''" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import confetti from 'canvas-confetti'
import { logEvent, events } from '@/services/analytics'
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfChips from '@/components/Chips/DfChips.vue'
import PlanMixin from '../PlanMixin.vue'

export default {
  mixins: [PlanMixin],
  name: 'ComingSoon',
  data() {
    return {
      hasCatalogFeedback: localStorage.getItem('has_catalog_feedback'),
      congratsColors: ['##F1C131', '#0E6634', '#2B5FA5'],
    }
  },
  components: { DfButton, DfChips },

  async created() {
    await this.getLastUpsellPlan()
  },

  computed: {
    planDisabled() {
      //FIXME: remove this when the full is available
      return process.env.VUE_APP_PLAN_LEVEL_MAX_DISABLED
    },

    contactWhatsapp() {
      return (
        process.env.VUE_APP_COMMERCIAL_WHATSAPP +
        this.$t('contato.msg_whatsapp_hire_plan', [this.upsellNameLastPlan])
      )
    },

    imgBanner() {
      return this.planDisabled
        ? '@/assets/img/catalog/banner-coming-soon.webp'
        : '@/assets/img/presentation_catalog.webp'
    },
  },

  methods: {
    likeFeature(iLikeIt) {
      if (iLikeIt) {
        this.congrats()
        logEvent(events.catalogModule.ComingSoon.clickedLikeButton)
      } else {
        logEvent(events.catalogModule.ComingSoon.clickedUnlikeButton)
      }
      this.$root.$emit(
        'notify',
        'success',
        this.$t('Catalog.ComingSoon.Toast.thanksForFeedback')
      )
      this.hasCatalogFeedback = true
      localStorage.setItem('has_catalog_feedback', true)
    },

    congrats() {
      confetti({
        spread: 70,
        origin: { y: 1 },
        colors: this.congratsColors,
      })
    },

    talkSeller() {
      const routeName = this.$route.matched.filter((route) => {
        return route.name !== undefined
      })[0].name

      logEvent(
        events.catalogModule.ComingSoon.getInTouch({
          menu: routeName,
        })
      )
      window.open(this.contactWhatsapp).focus()
    },
  },
}
</script>

<style scoped>
.coming-soon__title {
  margin: 5px 0 6px 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 2.375rem;
  line-height: 40px;
  color: #1a2b46;
}

.coming-soon__description {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #51594f;
}

.coming-soon__feedback-congratulations {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #51594f;
}
</style>
